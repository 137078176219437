@mixin justify-content($size) {
    &.#{$size}-start {
        display: flex;
        justify-content: flex-start;
        .col {
            flex-grow: 0;
            flex-basis: auto;
        }
    }
    &.#{$size}-center {
        display: flex;
        justify-content: center;
        .col {
            flex-grow: 0;
            flex-basis: auto;
        }
    }
    &.#{$size}-end {
        display: flex;
        justify-content: flex-end;
        .col {
            flex-grow: 0;
            flex-basis: auto;
        }
    }
    &.#{$size}-between {
        display: flex;
        justify-content: space-between;
        .col {
            flex-grow: 0;
            flex-basis: auto;
        }
    }
    &.#{$size}-around {
        display: flex;
        justify-content: space-around;
        .col {
            flex-grow: 0;
            flex-basis: auto;
        }
    }
    &.#{$size}-evenly {
        display: flex;
        justify-content: space-evenly;
        .col {
            flex-grow: 0;
            flex-basis: auto;
        }
    }
}

@mixin align-items($size) {
    &.#{$size}-stretch {
        display: flex;
        align-items: stretch;
    }
    &.#{$size}-top {
        display: flex;
        align-items: flex-start;
    }
    &.#{$size}-middle {
        display: flex;
        align-items: center;
    }
    &.#{$size}-bottom {
        display: flex;
        align-items: flex-end;
    }
}

@mixin auto-width($size) {
    &:not(.#{$size}-start):not(.#{$size}-center):not(.#{$size}-end):not(.#{$size}-between):not(.#{$size}-around):not(.#{$size}-evenly) .col {
        flex-grow: 1;
        flex-basis: 0;
    }
}

@mixin grid-reset {
    margin-left: 0;
    left: auto;
    right: auto;
    flex-grow: 0;
}

@mixin grid-classes($size, $i, $perc) {
    &.offset-#{$size}#{$i} {
        margin-left: $perc;
    }
    &.pull-#{$size}#{$i} {
        right: $perc;
    }
    &.push-#{$size}#{$i} {
        left: $perc;
    }
}

@mixin reverse($size) {
    &.#{$size}-reverse {
        display: flex;
        flex-direction: row-reverse;
    }
}

.container {
    width: 90%;
    margin: {
        right: auto;
        left: auto;
    }
    @media #{$large-and-up} {
        max-width: $container-max-width;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: {
        right: -1 * $gutter-width / 2;
        left: -1 * $gutter-width / 2;
    }
    @at-root ul#{&} {
        padding-left: 0;
        list-style: none;
    }
    &.m-reverse, &.m-start, &.m-center, &.m-end, &.m-between, &.m-around, &.m-evenly, &.m-stretch, &.m-top, &.m-middle, &.m-bottom,
    &.l-reverse, &.l-start, &.l-center, &.l-end, &.l-between, &.l-around, &.l-evenly, &.l-stretch, &.l-top, &.l-middle, &.l-bottom,
    &.xl-reverse, &.xl-start, &.xl-center, &.xl-end, &.xl-between, &.xl-around, &.xl-evenly, &.xl-stretch, &.xl-top, &.xl-middle, &.xl-bottom {
        display: revert;
    }
    @include reverse("s");
    @include justify-content("s");
    @include align-items("s");
    @media #{$medium-and-up} {
        @include reverse("m");
        @include justify-content("m");
        @include align-items("m");
    }
    @media #{$large-and-up} {
        @include reverse("l");
        @include justify-content("l");
        @include align-items("l");
    }
    @media #{$extra-large-and-up} {
        @include reverse("xl");
        @include justify-content("xl");
        @include align-items("xl");
    }
    .col {
        padding: {
            right: $gutter-width / 2;
            left: $gutter-width / 2;
        }
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: 0;
        &[class*="push-"],
        &[class*="pull-"] {
            position: relative;
        }
        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            &.s#{$i} {
                width: $perc;
                @include grid-reset;
                flex-basis: $perc;
            }
            $i: $i + 1;
        }
        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("s", $i, $perc);
            $i: $i + 1;
        }
        @media #{$medium-and-up} {
            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                &.m#{$i} {
                    width: $perc;
                    @include grid-reset;
                    flex-basis: $perc;
                }
                $i: $i + 1
            }
            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                @include grid-classes("m", $i, $perc);
                $i: $i + 1;
            }
        }
        @media #{$large-and-up} {
            $i: 1;
            @while $i <=$num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                &.l#{$i} {
                    width: $perc;
                    @include grid-reset;
                    flex-basis: $perc;
                }
                $i: $i + 1;
            }
            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                @include grid-classes("l", $i, $perc);
                $i: $i + 1;
            }
        }
        @media #{$extra-large-and-up} {
            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                &.xl#{$i} {
                    width: $perc;
                    @include grid-reset;
                    flex-basis: $perc;
                }
                $i: $i + 1;
            }
            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                @include grid-classes("xl", $i, $perc);
                $i: $i + 1;
            }
        }
    }
}